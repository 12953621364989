<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta && to.meta.documentTitle) {
          document.title = to.meta.documentTitle + ' | AAR'
        } else {
          document.title = 'AAR'
        }
      },
    },
  },
}
</script>
