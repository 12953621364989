import axios from "axios";
import store from "../store";
import Cookies from "js-cookie";

const CSRF_COOKIE_NAME = "rms_xsrf";

const rmsApi = axios.create({
  baseURL: "/api",
});

rmsApi.interceptors.request.use(function (config) {
  document.querySelectorAll(".form-group").forEach(function (element) {
    element.classList.remove("has-error");
  });
  document.querySelectorAll(".error-block").forEach(function (element) {
    element.innerHTML = "";
  });

  config.headers["X-CSRF-TOKEN"] = Cookies.get(CSRF_COOKIE_NAME);

  return config;
});

rmsApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
        case 419:
          store.dispatch("logout");
          store.dispatch("setFlashAlert", {
            message: error.response.data.message,
            variant: "warning",
          });
          break;
        case 403:
        case 404:
        case 409:
          store.dispatch("setFlashAlert", {
            message: error.response.data.message,
            variant: "warning",
          });
          break;
        case 422:
          store.dispatch("setFlashAlert", {
            message: "An error occurred.",
            variant: "warning",
          });
          let errors = error.response.data.errors;
          for (const property in errors) {
            let formGroup = property + "-form-group";
            document.querySelector("." + formGroup).classList.add("has-error");
            let errorBlock = property + "-error-block";
            document.querySelector("." + errorBlock).innerHTML = errors[property];
          }
          break;
        case 500:
        default:
          store.dispatch("setFlashAlert", {
            message: "Something went wrong! Please try again later.",
            variant: "danger",
          });
          break;
      }
    }

    throw error;
  }
);

export default rmsApi;
