const state = {
  message: '',
  variant: 'warning',
}

const getters = {
  message(state) {
    return state.message
  },
  variant(state) {
    return state.variant
  },
  hasMessage(state) {
    return state.message !== null &&
      state.message.length > 0
  }
}

const mutations = {
  setMessage(state, message) {
    state.message = message
  },
  setVariant(state, variant) {
    state.variant = variant
  },
}

const actions = {
  clearFlashAlert({commit}) {
    commit('setMessage', '')
    commit('setVariant', 'warning')
  },
  setFlashAlert({commit}, {message, variant}) {
    commit('setMessage', message || '')
    commit('setVariant', variant || 'warning')
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
