import axios from 'axios';
import 'bootstrap';
import $ from 'jquery';

/**
 * Use this file to register variables (or functions) that should be available
 * globally. Ideally you should make them available via the window object.
 * Register globals only when it makes sense to be accessible app-wide.
 */

window.$ = $;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;
window.axios.defaults.headers.withCredentials = true;
window.axios.defaults.headers.common['Accept'] = 'application/json'
window.axios.defaults.headers.common['Content-Type'] = 'application/json'
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next, we will load TinyMCE which is an open source rich text editor that
 * provides a WYSIWYG textarea for any system user to format input text.
 * This provides a better experience when submitting a Change Request.
 */

window.tinymce = require('tinymce')
