import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const router = new VueRouter({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./pages/index.vue"),
      meta: {
        documentTitle: "Home",
        isPublic: true,
      },
    },
    {
      path: "/attachments",
      name: "attachments",
      component: () => import("./pages/attachments/index.vue"),
      meta: {
        documentTitle: "Attachments",
        isAdmin: true,
      },
    },
    {
      path: "/change-requests",
      name: "change-requests",
      component: () => import("./pages/change-requests/index.vue"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/change-requests/create",
      name: "change-requests-create",
      component: () => import("./pages/change-requests/create"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/change-requests/report",
      name: "change-requests-report",
      component: () => import("./pages/change-requests/report"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/change-requests/search",
      name: "change-requests-search",
      component: () => import("./pages/change-requests/search"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/change-requests/:id(\\d+)/edit",
      name: "change-requests-edit",
      component: () => import("./pages/change-requests/edit"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/change-requests/:id(\\d+)",
      name: "change-requests-view",
      component: () => import("./pages/change-requests/view.vue"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/users/:id(\\d+)/change-requests",
      name: "users-change-requests",
      component: () => import("./pages/users/change-requests.vue"),
      meta: {
        documentTitle: "Change Requests",
      },
    },
    {
      path: "/comments",
      name: "comments",
      component: () => import("./pages/comments"),
      meta: {
        documentTitle: "Comments",
        isAdmin: true,
      },
    },
    {
      path: "/committees",
      name: "committees",
      component: () => import("./pages/committees"),
      meta: {
        documentTitle: "Committees",
        isAdmin: true,
      },
    },
    {
      path: "/committees/:id(\\d+)",
      name: "committees-view",
      component: () => import("./pages/committees/view"),
      meta: {
        documentTitle: "Committees",
        isAdmin: true,
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./pages/dashboard"),
      meta: {
        documentTitle: "Dashboard",
      },
    },
    {
      path: "/dispositions",
      name: "dispositions",
      component: () => import("./pages/dispositions"),
      meta: {
        documentTitle: "Dispositions",
        isAdmin: true,
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./pages/documents"),
      meta: {
        documentTitle: "Documents",
      },
    },
    {
      path: "/documents/:id(\\d+)",
      name: "documents-view",
      component: () => import("./pages/documents/view"),
      meta: {
        documentTitle: "Documents",
      },
    },
    {
      path: "/level-of-efforts",
      name: "level-of-efforts",
      component: () => import("./pages/level-of-efforts"),
      meta: {
        documentTitle: "Level of Efforts",
        isAdmin: true,
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("./pages/notifications"),
      meta: {
        documentTitle: "Notifications",
        isAdmin: true,
      },
    },
    {
      path: "/organizations",
      name: "organizations",
      component: () => import("./pages/organizations"),
      meta: {
        documentTitle: "Organizations",
        isAdmin: true,
      },
    },
    {
      path: "/passwords/change",
      name: "passwords-change",
      component: () => import("./pages/passwords/change"),
      meta: {
        documentTitle: "Credentials",
      },
    },
    {
      path: "/passwords/forgot",
      name: "passwords-forgot",
      component: () => import("./pages/passwords/forgot"),
      meta: {
        documentTitle: "Credentials",
        isPublic: true,
      },
    },
    {
      path: "/subscriptions",
      name: "subscriptions",
      component: () => import("./pages/subscriptions"),
      meta: {
        documentTitle: "Subscriptions",
        isAdmin: true,
      },
    },
    {
      path: "/subsystems",
      name: "subsystems",
      component: () => import("./pages/subsystems"),
      meta: {
        documentTitle: "Subsystems",
        isAdmin: true,
      },
    },
    {
      path: "/system-roles",
      name: "system-roles",
      component: () => import("./pages/system-roles"),
      meta: {
        documentTitle: "System Roles",
        isAdmin: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("./pages/users/index.vue"),
      meta: {
        documentTitle: "System Users",
        isAdmin: true,
      },
    },
    {
      path: "/users/create",
      name: "users-create",
      component: () => import("./pages/users/create"),
      meta: {
        documentTitle: "Registration",
        isPublic: true,
      },
    },
    {
      path: "/users/:id(\\d+)",
      name: "users-view",
      component: () => import("./pages/users/view"),
      meta: {
        documentTitle: "System Users",
      },
    },
    {
      path: "/workflow-stages",
      name: "workflow-stages",
      component: () => import("./pages/workflow-stages/index.vue"),
      meta: {
        documentTitle: "Workflow Stages",
        isAdmin: true,
      },
    },
    {
      path: "/:catchAll(.*)",
      name: "not-found",
      component: () => import("./pages/not-found"),
      meta: {
        documentTitle: "Not Found",
        isPublic: true,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return {
        x: 0,
        y: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch("clearFlashAlert");
  store.dispatch("refreshAuthState");
  let toRouteRequiresAuth = !to.meta.isPublic;
  let toRouteRequiresAdmin = to.meta.isAdmin ?? false;
  let isAuthenticated = store.state.auth.isAuthenticated;
  let isAdmin = store.getters.currentUser.role.is_admin;

  if (to.name === "home" && isAuthenticated) {
    next({ name: "dashboard" });
  } else if (toRouteRequiresAuth && !isAuthenticated) {
    next({ name: "home" });
    store.dispatch("setFlashAlert", { message: "Please login.", variant: "warning" });
  } else if (toRouteRequiresAdmin && !isAdmin) {
    from.name === null ? next({ name: "home" }) : next(false);
    store.dispatch("setFlashAlert", { message: "Route requires admin permissions.", variant: "warning" });
  } else {
    next();
  }
});

export default router;
