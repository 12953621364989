import rmsApi from '../../services/rmsApi'
import router from '../../router'
import Cookies from 'js-cookie'

const JWT_COOKIE_NAME = 'rms_jwt'

const state = {
  accessToken: undefined,
  isAuthenticated: false
}

const getters = {
  currentUser(state) {
    let token = JSON.parse('{}')

    if (state.accessToken) {
      let payload = state.accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
      token = JSON.parse(
        decodeURIComponent(
          atob(payload)
            .split('')
            .map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )
      )
    }

    return {
      id: token.sub ?? null,
      name_username: token.name_username ?? '',
      role: {
        is_admin: token.is_admin ?? false
      },
      voting_committee_ids: JSON.parse(token.voting_committee_ids ?? '[]')
    }
  }
}

const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  }
}

const actions = {
  logout({ dispatch }) {
    let token = Cookies.get(JWT_COOKIE_NAME)

    if (token) {
      rmsApi.delete('tokens/' + token).catch((error) => console.log(error))
    }

    Cookies.remove(JWT_COOKIE_NAME)
    dispatch('refreshAuthState')
    if (router.currentRoute.name !== 'home') {
      router.push({ name: 'home' })
    }
  },
  refreshAuthState({ commit }) {
    let accessToken = Cookies.get(JWT_COOKIE_NAME)
    commit('setAccessToken', accessToken)
    commit('setIsAuthenticated', accessToken !== undefined)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
